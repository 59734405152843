import * as React from "react";
import { useIntl } from "gatsby-plugin-react-intl";
import TextPage, { TextBlock } from '../components/text-page';

const CriticalSuccessFactors = () => {
  const intl = useIntl();
  const messages = intl.messages;
  const textBlocks: TextBlock[] = [{
    header: `${messages.critical_success_factors}`,
    message: (
      <div className="ui bulleted list">
        <div className="item">{messages.message_critical_success_factors_item1}</div>
        <div className="item">{messages.message_critical_success_factors_item2}</div>
        <div className="item">{messages.message_critical_success_factors_item3}</div>
        <div className="item">{messages.message_critical_success_factors_item4}</div>
        <div className="item">{messages.message_critical_success_factors_item5}</div>
        <div className="item">{messages.message_critical_success_factors_item6}</div>
        <div className="item">{messages.message_critical_success_factors_item7}</div>
        <div className="item">{messages.message_critical_success_factors_item8}</div>
        <div className="item">{messages.message_critical_success_factors_item9}</div>
        <div className="item">{messages.message_critical_success_factors_item10}</div>
      </div>
    )
  }];

  return (<TextPage locale={intl.locale} title={`${messages.critical_success_factors}`} textBlocks={textBlocks}/>);
};

export default CriticalSuccessFactors;